// import SanityBlockContent from "@sanity/block-content-to-react"
import React from "react"
// import { HeartIcon } from "@heroicons/react/outline"
// import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/new-design/Layout"
import { DescCard, TemplateHeader } from "../components/template"
import Seo from "../components/seo"
export default function Page({ location, pageContext: { data } }) {
  // console.log(data)
  const metaImage = {
    src: data.image.asset?.url,
    width: data.image.asset?.metadata?.dimensions?.width,
    height: data.image.asset?.metadata?.dimensions?.height,
  }
  return (
    <Layout>
      <Seo
        template
        image={metaImage}
        title={data.title || data.name}
        pathname={location.pathname}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <TemplateHeader
        fullWidth
        name={data.name}
        imgSrc={data.image.asset?.url}
        image={
          data.image
            ? data.image.asset && data.image.asset.gatsbyImageData
            : null
        }
        link="/actions/car/request?name="
      />
      <DescCard data={data} className="pt-12 " />
      {/* <Booking /> */}
    </Layout>
  )
}
